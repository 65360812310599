import React from 'react';
import WorkExperienceAccordion from '../components/WorkExperienceAccordion';
import '../components/styles/CV.css'; 
import Education from '../components/Education';

function CV() {
    return (
        <div>
            <WorkExperienceAccordion />
            <Education />
        </div>
    );
}

export default CV;