
import React from 'react';
import '../components/styles/Archive.css'; // CSS for styling the archive page

function Archive() {
    // Placeholder for articles
    const articles = [
        { 
            title: 'Article 1', 
            summary: 'Summary of article 1...', 
            imageUrl: 'https://via.placeholder.com/150' // Placeholder image
        },
        { 
            title: 'Article 1', 
            summary: 'Summary of article 1...', 
            imageUrl: 'https://via.placeholder.com/150' // Placeholder image
        },
        { 
            title: 'Article 1', 
            summary: 'Summary of article 1...', 
            imageUrl: 'https://via.placeholder.com/150' // Placeholder image
        },
        { 
            title: 'Article 1', 
            summary: 'Summary of article 1...', 
            imageUrl: 'https://via.placeholder.com/150' // Placeholder image
        },
        // ... more articles
    ];

    return (
        <div className="archive-page">
            <h1>Archive</h1>
            {articles.map((article, index) => (
                <div key={index} className="article-box">
                    <img src={article.imageUrl} alt={article.title} className="article-image" />
                    <div className="article-content">
                        <h2>{article.title}</h2>
                        <p>{article.summary}</p>
                        <a href={`/articles/${index}`}>Read more</a> {/* Update href as needed */}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Archive;
