import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedium, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './styles/Footer.css'; // Make sure to create this CSS file

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-column">
                {/* Same points as the navbar */}
                <a href="/">Experience</a>
                <a href="/archive">Archive</a>
                <a href="/newsletter">Newsletter</a>
                {/* Add other links as needed */}
            </div>
            <div className="footer-column">
                {/* Title and copyright */}
                <p>Felix Kuhlmann</p>
                <p>© 2023 All Rights Reserved</p>
            </div>
            <div className="footer-column">
                {/* Social media icons */}
                <a href="https://www.linkedin.com/in/felix-kuhlmann-676436201/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} className="icon"/>   
                </a>
                <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} className="icon"/>
                </a>
                <a href="https://medium.com/@kuhlmannfelix98" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faMedium} className="icon"/>
                </a>
                {/* Add other social media links as needed */}
            </div>
        </footer>
    );
}

export default Footer;
