import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedium, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './styles/navbarstyle.css';

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <div className="navbar">
                <div className="logo">
                    {/* Add your logo here, for example, an image or text */}
                    <img src="../blog/public/Logo512.png" alt="Felix Kuhlmann" />
                </div>
                <div className={`hamburger-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>

            <nav className={`slide-out-menu ${isMenuOpen ? 'open' : ''}`}>
                <div className="menu-links">
                    <a href="/">Homepage</a>
                    <a href="/projects">Projects</a>
                    <a href="/CV">CV</a>
                    <a href="/archive">Archive</a>
                    <a href="https://felixkuhlmann.substack.com/publish/home">Newsletter</a>
                </div>
                <div className="social-media-links">
                    <a href="https://www.linkedin.com/in/felix-kuhlmann-676436201/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} className="icon"/>
                    </a>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTwitter} className="icon"/>
                    </a>
                    <a href="https://medium.com/@kuhlmannfelix98" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faMedium} className="icon"/>
                    </a>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
