import React from 'react';
import '../index.css'; // Adjusted path for CSS
import Hello from '../components/hello'; // Adjusted path for Hello component // Adjusted path for WorkExperienceAccordion
import Footer from '../components/Footer'; // Adjusted path for Footer
import NewsletterSignUp from '../components/newsletter';

function Homepage() {
    return (
        <div>
            <Hello />
            <NewsletterSignUp />
            <Footer />
        </div>
    );
}

export default Homepage;
