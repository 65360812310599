import React, { useState, useRef } from 'react';
import './styles/WorkExperienceAccordion.css';

function Education() {
    const [openItems, setOpenItems] = useState({});
    const contentRefs = useRef({});

    const toggleItem = (index) => {
        setOpenItems(prevOpenItems => {
            const isItemOpen = !!prevOpenItems[index];
            const newOpenItems = { ...prevOpenItems, [index]: !isItemOpen };

            const currentContent = contentRefs.current[index];
            currentContent.style.maxHeight = isItemOpen ? '0px' : `${currentContent.scrollHeight}px`;

            return newOpenItems;
        });
    };

    const Education = [
        {
            schoolName: "University of applied sciences",
            logoUrl: "https://upload.wikimedia.org/wikipedia/commons/6/62/HAW_Marke_RGB-72dpi.png",
            position: "BSc. international Management and foreign trade",
            location: "Hamburg, Germany",
            timeAtCompany: "September 2023 - Present",
            details: (
                <>
                    <p>Antler, a global venture capital firm, specializes in nurturing the next generation of technology innovators. We've supported hundreds of start-ups in 28 locations over the past six years. Our approach involves selecting driven entrepreneurs, facilitating co-founder partnerships, and connecting them to a global mentor network. We provide initial funding, helping founders build businesses that positively impact both local and global economies.</p>
                    <p>In my role as a Scouting Analyst at Antler, I focus on:</p>
                    <ul>
                        <li>Scouting and Engagement: Identifying and engaging potential founders for our Nordic startup program, guiding the next wave of innovators.</li>
                        <li>Idea Evaluation: Assessing startup ideas against current tech trends, ensuring investment in high-potential innovations.</li>
                        <li>Ecosystem Mapping: Mapping the Nordic startup landscape, gaining insights into various sectors and emerging trends.</li>
                    </ul>
                </>
            )
        },
        {
            schoolNameName: "PGS Dassel",
            logoUrl: "https://static.eppendorf.com/assets/website/images/logoEppendorf.svg",
            position: "High School",
            location: "Dassel, Germany",
            timeAtCompany: "September 2023 - Present",
            details: (
                <>
                    <p>Antler, a global venture capital firm, specializes in nurturing the next generation of technology innovators. We've supported hundreds of start-ups in 28 locations over the past six years. Our approach involves selecting driven entrepreneurs, facilitating co-founder partnerships, and connecting them to a global mentor network. We provide initial funding, helping founders build businesses that positively impact both local and global economies.</p>
                    <p>In my role as a Scouting Analyst at Antler, I focus on:</p>
                    <ul>
                        <li>Scouting and Engagement: Identifying and engaging potential founders for our Nordic startup program, guiding the next wave of innovators.</li>
                        <li>Idea Evaluation: Assessing startup ideas against current tech trends, ensuring investment in high-potential innovations.</li>
                        <li>Ecosystem Mapping: Mapping the Nordic startup landscape, gaining insights into various sectors and emerging trends.</li>
                    </ul>
                </>
            )
        },
        
    ];

    return (
        <div className="accordion">
            <h2>Education</h2>
            {Education.map((item, index) => (
                <div key={index} className={`accordion-item ${openItems[index] ? 'active' : ''}`}>
                    <div className="accordion-title" onClick={() => toggleItem(index)}>
                    <div className="title-content">
                        <img src={item.logoUrl} alt={`${item.schoolName} logo`} className="company-logo" />
                        <div className="position-divider"></div>
                        <p>{`${item.position}`}</p>
                        
                    </div>

                        <span className="accordion-indicator">{openItems[index] ? '-' : '+'}</span>
                    </div>
                    <div ref={el => contentRefs.current[index] = el} className="accordion-content">
                        <div className="job details">
                            {`${item.timeAtCompany}`}
                        </div>
                        <span className="job details">
                            {`${item.location}`}
                        </span>
                        <p>About my time at {`${item.schoolName}`} </p>
                        {item.details}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Education;
