import React from 'react';
import './styles/hello.css';

function Hello() {
    return (
        <div className="main-content">
            <h1>Hi, I am <br/> Felix Kuhlmann</h1>
        </div>
    );
}

export default Hello;