// In App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Homepage from './pages/Homepage';
import Archive from './pages/Archive';
import CV from './pages/CV';
// Import other pages as needed

function App() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/archive" element={<Archive />} />
                <Route path="/CV" element={<CV />} />
                {/* Define other routes here */}
            </Routes>
        </Router>
    );
}

export default App;
