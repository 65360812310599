import React, { useState } from 'react';
import './styles/newsletter.css';

const NewsletterSignUp = () => {
    const [formData, setFormData] = useState({ name: '', email: '' });

    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Here you would typically handle the form submission, like sending data to your server
      console.log('Form Data:', formData);

    };

  return (
    <div className="newsletter-signup">
      <h2>Sign up for my Newsletter</h2>
      <form onSubmit={handleSubmit}>
        <input 
          type="email" 
          name="email" 
          placeholder="Email" 
          value={formData.email} 
          onChange={handleChange} 
        />
        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
};

export default NewsletterSignUp;
